import React from 'react'
import Calendar from '../../components/calendar'
import PageWrapper from "../../components/PageWrapper";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'

function index() {
    return (
        <>
              <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Rendez-vous expert crédit immobilier</title>
                <meta name="description" content="
              Un rendez-vous de 30 minutes pour discuter de votre projet immobilier" />
        </Helmet>
      	<Header />
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Crédit immobilier</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)', maxWidth: 710, margin: 'auto'}}><span>Crédit immobilier</span> - 30min avec un expert pour 49€</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)', maxWidth: 710, margin: 'auto', marginTop: 20}}>Ni un courtier, ni une banque. Posez toutes vos questions à un expert en crédit immobilier même si votre projet immo n'est pas définit.</h2>
          {/* <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur compte courant</span> pour déterminer vos priorités : promo, carte gratuite, frais.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponible dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleur compte courant</span>, celui qui correspond le plus à vos besoins.</p>
          </div> */}

          <div style={{marginTop: 0, marginBottom: 150, height: 750}}>
            <Calendar/>
            </div>
            </Container>
          </Container>  

            <Footer/>
      </PageWrapper>
        </>
    )
}

export default index
