import React from 'react'
import { InlineWidget } from "react-calendly";

function calendar() {
    return (
        <div>
             <InlineWidget url="https://calendly.com/kohdao/30min?month=2021-12&date=2021-12-18" styles={{height: '1000px'
}}/>
        </div>
    )
}

export default calendar
